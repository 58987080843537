import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '*', beforeEnter: (to, from, next) => { next('/404') } },
  {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */'../views/pages/404.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/pages/Home.vue')
  },
  {
    path: '/preescolar',
    name: 'preescolar',
    component: () => import(/* webpackChunkName: "preescolar" */ '../views/pages/Preescolar.vue')
  },
  {
    path: '/primaria',
    name: 'primaria',
    component: () => import(/* webpackChunkName: "primaria" */ '../views/pages/Primaria.vue')
  },
  {
    path: '/secundaria',
    name: 'secundaria',
    component: () => import(/* webpackChunkName: "secundaria" */ '../views/pages/Secundaria.vue')
  },
  {
    path: '/bachillerato',
    name: 'bachillerato',
    component: () => import(/* webpackChunkName: "bachillerato" */ '../views/pages/Preparatoria.vue')
  },
  {
    path: '/acerca-de-nosotros',
    name: 'acerca-de-nosotros',
    component: () => import(/* webpackChunkName: "acerca de nosotros" */ '../views/pages/SobreNosotros.vue')
  },
  {
    path: '/admisiones',
    name: 'admisiones',
    component: () => import(/* webpackChunkName: "contacto" */ '../components/contacto/Admisiones.vue')
  },
  {
    path: '/bolsa-de-trabajo',
    name: 'bolsa-de-trabajo',
    component: () => import(/* webpackChunkName: "contacto" */ '../components/contacto/BolsaDeTrabajo.vue')
  },
  {
    path: '/directorio-escolar',
    name: 'directorio-escolar',
    component: () => import(/* webpackChunkName: "contacto" */ '../components/contacto/DirectorioEscolar.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import(/* webpackChunkName: "eventos" */ '../views/pages/Eventos.vue')
  },
  {
    path: '/galeria',
    name: 'galeria',
    component: () => import(/* webpackChunkName: "galeria" */ '../views/pages/ComingSoon.vue')
  },
  {
    path: '/politicas-de-privacidad',
    name: 'politicas-de-privacidad',
    component: () => import(/* webpackChunkName: "politicas de privacidad" */ '../views/pages/ComingSoon.vue')
  },
  {
    path: '/plataformas',
    name: 'plataformas',
    component: () => import(/* webpackChunkName: "politicas de privacidad" */ '../views/pages/Plataformas.vue')
  },
  {
    path: '/ingles',
    name: 'ingles',
    component: () => import(/* webpackChunkName: "Inglés" */ '../views/pages/Ingles.vue')
  },
  {
    path: '/deportes',
    name: 'deportes',
    component: () => import(/* webpackChunkName: "Deportes" */ '../views/pages/Deportes.vue')
  },
  {
    path: '/pastoral',
    name: 'pastoral',
    component: () => import(/* webpackChunkName: "Pastoral" */ '../views/pages/Pastoral.vue')
  },
  {
    path: '/coe',
    name: 'coe',
    component: () => import(/* webpackChunkName: "COE" */ '../views/pages/COE.vue')
  },
  {
    path: '/solicitud-nuevo-ingreso',
    name: 'solicitud-nuevo-ingreso',
    component: () => import(/* webpackChunkName: "COE" */ '../views/pages/SolicitudNuevoIngreso.vue') 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) {
      return { x: 0, y: 0, behavior: 'smooth', }    
    }
      
  }
})

export default router
